
import VDPopupListbox from "@/components/pickers/VDPopupListbox.vue";
import { OrganizationType, useGetOrganisationsQuery } from "@/graphql/types";
import { useResult } from "@vue/apollo-composable";
import { computed, defineComponent, PropType, ref } from "vue";
import { VDPopupListboxClass } from "./VDPopupListbox";

export default defineComponent({
  components: {
    VDPopupListbox,
  },
  emits: ["addedOrganization"],
  props: {
    addedOrganizations: {
      type: Array as PropType<OrganizationType[]>,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    // VDOM
    const listbox = ref<VDPopupListboxClass>();

    // Refs
    const isEnabled = ref(false);

    // Data
    const { result, loading, error } = useGetOrganisationsQuery(() => ({
      enabled: isEnabled.value,
    }));

    return {
      organizations: computed(
        () =>
          useResult(result, null, (data) => data?.organizations?.items)
            .value?.slice()
            ?.sort((a, b) => (a?.name ?? "").localeCompare(b?.name ?? "")) ?? []
      ),
      listbox,
      loading,
      error,
      toggle: (event: Event) => {
        isEnabled.value = true;
        listbox.value?.toggle(event);
      },
    };
  },
});
