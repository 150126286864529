import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"z-index":"99999","opacity":"0.3"},
  class: "position-absolute top-0 left-0 h-100 w-100 bg-dark"
}
const _hoisted_2 = {
  key: 0,
  class: "d-flex py-4 justify-content-center"
}
const _hoisted_3 = { class: "fw-bolder text-uppercase" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!
  const _component_error = _resolveComponent("error")!
  const _component_Listbox = _resolveComponent("Listbox")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_openBlock(), _createBlock(_component_OverlayPanel, {
    appendTo: _ctx.appendTo,
    ref: "overlay",
    dismissable: true
  }, {
    default: _withCtx(() => [
      (_ctx.disabled)
        ? (_openBlock(), _createBlock("div", _hoisted_1))
        : _createCommentVNode("", true),
      _createVNode(_component_Listbox, {
        disabled: _ctx.disabled,
        optionGroupLabel: _ctx.groupedOptions ? 'name' : null,
        optionGroupChildren: _ctx.groupedOptions ? 'items' : null,
        filter: true,
        filterFields: _ctx.filterFields,
        filterPlaceholder: _ctx.searchPlaceholder,
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.optionAdded($event))),
        options: _ctx.groupedOptions ? _ctx.groupedOptions : _ctx.options,
        optionDisabled: _ctx.isOptionDisabled
      }, {
        empty: _withCtx(() => [
          (_ctx.isLoading)
            ? (_openBlock(), _createBlock("div", _hoisted_2, [
                _createVNode(_component_spinner)
              ]))
            : (_openBlock(), _createBlock(_component_error, {
                key: 1,
                error: {
            title: 'Her er tomt',
            message: _ctx.emptyMessage,
          }
              }, null, 8, ["error"]))
        ]),
        option: _withCtx(({ option }) => [
          _renderSlot(_ctx.$slots, "default", { option: option })
        ]),
        optiongroup: _withCtx(({ option }) => [
          _createVNode("span", _hoisted_3, _toDisplayString(option.name), 1)
        ]),
        emptyfilter: _withCtx(() => [
          _createVNode(_component_error, { error: {
            title: 'Ingen resultater',
            message: 'Din søgning gav ingen resultater',
          } })
        ]),
        _: 1
      }, 8, ["disabled", "optionGroupLabel", "optionGroupChildren", "filterFields", "filterPlaceholder", "options", "optionDisabled"])
    ]),
    _: 1
  }, 8, ["appendTo"]))
}