import { useRemoveRoleAssigneeMutation } from "@/graphql/types";
import gql from "graphql-tag";

gql`
  mutation RemoveRoleAssignee($roleId: ID!, $organizationId: ID!) {
    removeRoleAssignee(roleId: $roleId, organizationId: $organizationId)
  }
`;
export default function () {
  const mutation = useRemoveRoleAssigneeMutation({});

  function call(organizationId: string, roleId: string) {
    return mutation.mutate({
      organizationId: organizationId,
      roleId: roleId,
    });
  }

  return {
    ...mutation,
    call,
  };
}
