import {
  GetApplicationDocument,
  GetApplicationQuery,
  GetApplicationQueryVariables,
  RoleType,
  useUpdateRoleMutation,
} from "@/graphql/types";
import { QueryOptions } from "@apollo/client";
import gql from "graphql-tag";

gql`
  mutation UpdateRole($input: UpdateRoleInputType!) {
    updateRole(input: $input) {
      ...RoleParts
    }
  }
`;

export default function () {
  const mutation = useUpdateRoleMutation({});

  function call(applicationId: string, role: RoleType) {
    mutation.mutate(
      {
        input: {
          name: role.name,
          roleId: role.roleId,
          description: role.description ?? "",
          attribute: role.attribute,
        },
      },
      {
        update: (cache, { data: update }) => {
          const parentQuery: QueryOptions<
            GetApplicationQueryVariables,
            GetApplicationQuery
          > = {
            query: GetApplicationDocument,
            variables: {
              applicationId: applicationId,
            },
          };
          const cachedData = cache.readQuery(parentQuery);
          cache.writeQuery({
            ...parentQuery,
            data: {
              application: {
                ...cachedData?.application,
                roles: cachedData?.application?.roles?.map((element) =>
                  element?.roleId == update?.updateRole?.roleId
                    ? { ...update?.updateRole }
                    : element
                ),
              },
            },
          });
        },
      }
    );
  }

  return {
    ...mutation,
    call,
  };
}
