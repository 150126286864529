import {
  GetApplicationDocument,
  GetApplicationQuery,
  GetApplicationQueryVariables,
  GetApplicationsDocument,
  useDeleteRoleMutation,
} from "@/graphql/types";
import { QueryOptions } from "@apollo/client";
import gql from "graphql-tag";

gql`
  mutation DeleteRole($id: ID!) {
    deleteRole(roleId: $id)
  }
`;

export default function () {
  const mutation = useDeleteRoleMutation({});

  function call(applicationId: string, roleId: string) {
    mutation.mutate(
      {
        id: roleId,
      },
      {
        refetchQueries: [{ query: GetApplicationsDocument }],
        update: (cache) => {
          const parentQuery: QueryOptions<
            GetApplicationQueryVariables,
            GetApplicationQuery
          > = {
            query: GetApplicationDocument,
            variables: {
              applicationId: applicationId,
            },
          };
          const cachedData = cache.readQuery(parentQuery);
          cache.writeQuery({
            ...parentQuery,
            data: {
              application: {
                ...cachedData?.application,
                roles:
                  cachedData?.application?.roles?.filter(
                    (element) => element?.roleId != roleId
                  ) ?? [],
              },
            },
          });
        },
      }
    );
  }

  return {
    ...mutation,
    call,
  };
}
