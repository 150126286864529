import { useAddRoleAssigneeMutation } from "@/graphql/types";
import gql from "graphql-tag";

gql`
  mutation AddRoleAssignee($input: AddRoleAssigneeInputType!) {
    addRoleAssignee(input: $input)
  }
`;
export default function () {
  const mutation = useAddRoleAssigneeMutation({});
  function call(organizationId: string, roleId: string) {
    return mutation.mutate({
      input: {
        organizationId: organizationId,
        roleId: roleId,
      },
    });
  }

  return {
    ...mutation,
    call,
  };
}
