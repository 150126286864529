
import GroupedItems from "@/models/GroupedItems";
import OverlayPanel from "primevue/overlaypanel";
import { defineComponent, PropType, ref } from "vue";
import Error from "../Error.vue";
import Spinner from "../Spinner.vue";

export default defineComponent({
  components: {
    Spinner,
    Error,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    filterFields: {
      type: Array as PropType<string[]>,
      default: () => ["name"],
    },
    emptyMessage: {
      type: String,
      default: "Der er ikke oprettet noget du kan vælge endnu",
    },
    appendTo: {
      type: String,
    },
    searchPlaceholder: {
      type: String,
      default: "Søg her...",
    },
    groupedOptions: {
      type: Array as PropType<GroupedItems<unknown>[]>,
      required: false,
    },
    options: {
      type: Array as PropType<unknown[]>,
      required: false,
    },
    disabledOptions: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dataKey: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const overlay = ref<OverlayPanel | null>(null);
    return {
      overlay,
      optionAdded: (event: any) => {
        emit("optionAdded", event.value);
      },
      toggle: (event: Event) => overlay.value?.toggle(event),
      isOptionDisabled: (option: any) =>
        props.disabledOptions.find(
          (element) => element[props.dataKey] == option[props.dataKey]
        ),
    };
  },
});
